import { defineMessages } from 'react-intl'

const msg = defineMessages({
  title: {
    defaultMessage:
      'A bake customised especially for you and your next occasion!'
  },
  description: {
    defaultMessage:
      'Our bakers are ready to make you your special cake, just the way you want it!'
  },
  textOne: {
    defaultMessage: 'Like this photo but pink'
  },
  textTwo: {
    defaultMessage: 'Without any nuts please, family allergies'
  },
  textThree: {
    defaultMessage: 'I like this but in a square shape'
  },
  textFour: {
    defaultMessage: 'Drip cake with blue theme'
  },
  textFive: {
    defaultMessage: 'Can you make it egg free'
  },
  texSix: {
    defaultMessage: 'One layer chocolate and one layer vanilla'
  }
})

export default msg
