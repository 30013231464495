import React, { FC } from 'react'

import { BoxProps, Typography } from '@mui/material'

interface ChatBubbleProps extends BoxProps {
  isMe?: boolean
}

const ChatBubble: FC<ChatBubbleProps> = ({
  isMe,
  children,
  display = 'block'
}) => {
  return (
    <Typography
      display={display}
      className={isMe ? 'from-me' : 'from-them'}
      fontSize={20}
      letterSpacing={-1}
      sx={{
        position: 'relative',
        borderRadius: '25px',
        lineHeight: { xs: '24px', md: '20px' },
        padding: '14px 20px 12px 20px',

        maxWidth: 460,
        backgroundColor: 'third.main',
        color: '#fff',

        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          bottom: '-1px',
          height: '16px'
        },

        '&.from-me::before': {
          borderBottomLeftRadius: '0.8rem 0.7rem',
          borderRight: '1rem solid',
          borderRightColor: 'third.main',
          right: '-4px',
          transform: 'translate(0px, -2px) rotate(2deg)'
        },

        '&.from-me::after': {
          backgroundColor: '#fff',
          borderBottomLeftRadius: '10px',
          right: '-40px',
          transform: 'translate(-30px, -2px) rotate(-3deg)',
          width: 10
        },

        '&.from-them': {
          backgroundColor: 'secondary.main',
          color: '#fff'
        },

        '&.from-them::before': {
          borderBottomRightRadius: '0.8rem 0.7rem',
          borderLeft: '1rem solid',
          borderLeftColor: 'secondary.main',
          left: '-4px',
          transform: 'translate(0px, -2px) rotate(2deg)'
        },

        '&.from-them::after': {
          backgroundColor: '#fff',
          borderBottomRightRadius: '10px',
          left: '21px',
          transform: 'translate(-30px, -2px) rotate(3deg)',
          width: 10
        }
      }}
    >
      {children}
    </Typography>
  )
}

export default ChatBubble
