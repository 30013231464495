import React from 'react'

import { Stack, Box } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'

import Text from '~/components/base/Text'

import FindMakerBtn from '../FindMakerBtn'
import SectionContainer from '../SectionContainer'

import ChatBubble from './ChatBubble'
import msg from './ChatSection.messages'

const ChatSection = () => {
  const { formatMessage } = useIntl()

  const chatBubbleData = [
    {
      id: 0,
      text: formatMessage(msg.textOne),
      isMe: false
    },
    {
      id: 1,
      text: formatMessage(msg.textTwo),
      isMe: true
    },
    {
      id: 2,
      text: formatMessage(msg.textThree),
      isMe: false,
      display: { xs: 'none', md: 'block' }
    },
    {
      id: 3,
      text: formatMessage(msg.textFour),
      isMe: true,
      display: { xs: 'none', md: 'block' }
    },
    {
      id: 4,
      text: formatMessage(msg.textFive),
      isMe: false
    },
    {
      id: 5,
      text: formatMessage(msg.texSix),
      isMe: true,
      display: { xs: 'none', md: 'block' }
    }
  ]

  return (
    <SectionContainer>
      <Box mb={3}>
        <Text
          size={{ xs: 'h3', md: 'h2' }}
          component="h2"
          color="textPrimary"
          textAlign={{ xs: 'center' }}
          fontWeight={600}
          mb={2}
        >
          <FormattedMessage {...msg.title} />
        </Text>

        <Text
          size={{ xs: 'body1' }}
          textAlign={{ xs: 'center' }}
          maxWidth={680}
          mx="auto"
          color="textSecondary"
        >
          <FormattedMessage {...msg.description} />
        </Text>
      </Box>

      <Stack
        direction={{ xs: 'column', md: 'row' }}
        flexWrap="wrap"
        justifyContent={{ xs: 'flex-start', md: 'center' }}
        columnGap={2}
        rowGap={2}
        mb={7}
      >
        {chatBubbleData.map(({ id, text, isMe, display }) => {
          return (
            <ChatBubble key={id} isMe={isMe} display={display}>
              {text}
            </ChatBubble>
          )
        })}
      </Stack>

      <FindMakerBtn />
    </SectionContainer>
  )
}

export default ChatSection
